import $ from 'jquery';

export default function () {
  let article = $('.article-body');

  if (article.length > 0) {
    article.find('blockquote').each(function (i, el) {
      let blockquote = $(el);
      blockquote.wrapInner('<div></div>');
    });
  }
}
