import $ from 'jquery';
import debounce from './debounce';

export default function () {
  $(document).on('click', '#navbar-toggler', function () {
    $('body').toggleClass('show-navbar-nav');
  });

  handleDropdown();

  window.addEventListener('resize', handleDropdown);
}

let handleDropdown = debounce(function () {
  $(document).off('click', '.nav-link, .languages-dropdown-trigger');

  if ($(window).width() < 992) {
    $(document).on('click', '.nav-link, .languages-dropdown-trigger', function () {
      let dropdown = $(this).closest('.dropdown');
      let dropdownMenu = $(this).next();

      $('.dropdown').removeClass('show');
      dropdown.addClass('show');

      if (dropdownMenu.is(':visible')) {
        dropdown.removeClass('show');
        dropdownMenu.slideUp('slow');
      }

      if (!dropdownMenu.is(':visible')) {
        $('.dropdown-menu:visible').slideUp('slow');
        dropdownMenu.slideDown('slow');
      }
    });
  }
}, 250);
