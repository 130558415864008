import $ from 'jquery';

export default function () {
  $('#search-bar-input').focusin(function () {
    $(this).closest('form').addClass('-focusin -has-value');
  });

  $('#search-bar-input').focusout(function () {
    $(this).closest('form').removeClass('-focusin');

    if (!$.trim($(this).val()).length) {
      $(this).closest('form').removeClass('-has-value');
    }
  });

  if ($.trim($('#search-input').val()).length) {
    $('#search-input').closest('form').addClass('-focusin -has-value');
  }

  $('#search-input').keyup(function () {
    if ($.trim($(this).val()).length) {
      $(this).closest('.search-form').addClass('-has-value');
    } else {
      $(this).closest('.search-form').removeClass('-has-value');
    }
  });

  $('.clear-value').on('click', function () {
    let form = $(this).closest('form');
    form.find('input[type="text"]').val('');
    form.removeClass('-focusin -has-value');
  });
}
