import navbar from './navbar';
import blockquote from './blockquote';
import search from './search';
import shareables from './shareables';
import $ from 'jquery';

export default function () {
  $(function () {
    navbar();
    blockquote();
    search();
    shareables();
  });
}
