import $ from 'jquery';

export default function () {
  let languageSelect = $('#language');

  if (languageSelect.length > 0) {
    languageSelect.change(function () {
      let type = languageSelect.data('shareables-type');
      let location = `/shareables/${languageSelect.val()}`;
      if (type) {
        location = location + `/${type}`;
      }

      window.location = location;
    });
  }
}
